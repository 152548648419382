import { pushEvent } from "@wbly/common";
import type { DataLayer } from "@wbly/common";

export const trackBrandHeroCtaClick = (id: string, ctaLink: string) =>
  pushEvent(
    window?.dataLayer as DataLayer[],
    `homepage`,
    `${id}`,
    `Redirected to ${ctaLink}`
  );
